import React from 'react'
import { IdealImage } from './shared/IdealImage'
import { replaceWildcards } from '../lib/helper/helper'

type Props = {
  coverImage?: any
  h1?: string
  h2?: string
  name?: string
  nameDescription?: string
  isLandingPage?: boolean
  wildcards?: Wildcards
  bannerDestination?: AnyDestination
}

export function PageBanner({
  coverImage,
  h1,
  h2,
  name,
  nameDescription,
  isLandingPage,
  wildcards,
}: Props) {
  const renderBannerContent = () => (
    <div className="mx-3 xs:mx-8 flex flex-col gap-1 xs:gap-3 z-20 text-white mb-6 xs:mb-12">
      {isLandingPage ? (
        <>
          <h1 className="xs:text-6xl text-3xl font-medium font-rubik uppercase">
            {replaceWildcards(h1, wildcards)}
          </h1>
          <h2 className="xs:text-2xl text-2xl mt-2">
            {replaceWildcards(h2, wildcards)}
          </h2>
        </>
      ) : (
        <>
          <div className="xs:text-6xl text-3xl font-medium uppercase">
            {replaceWildcards(name, wildcards)}
          </div>
          <div className="xs:text-2xl text-xl mt-2">
            {replaceWildcards(nameDescription, wildcards)}
          </div>
        </>
      )}
    </div>
  )

  const heightClasses = isLandingPage ? 'h-120' : 'xs:h-96 h-80'

  return (
    <>
      <div className={`relative ${heightClasses} xs:mb-10 overflow-hidden`}>
        <div className="absolute inset-0 z-0 xs:rounded-md overflow-hidden">
          <IdealImage
            title={replaceWildcards((name || h1) + ' - SIGHT2SIGHT', wildcards)}
            alt={replaceWildcards((name || h1) + ' - SIGHT2SIGHT', wildcards)}
            image={coverImage}
            priority={true}
          />
        </div>
        <div className="absolute bottom-0 left-0 right-0 h-1/2 z-20 xs:rounded-md overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent"></div>
        </div>
        <div className="flex flex-col items-stretch justify-between gap-16 h-full z-40">
          <div />
          {renderBannerContent()}
        </div>
      </div>
      {!isLandingPage && h1 && (
        <h1 className="uppercase font-medium text-2xl xs:text-3xl mt-5 mb-3 mx-3 xs:mx-0">
          <b>{replaceWildcards(h1, wildcards)}</b>
        </h1>
      )}
    </>
  )
}
